.quem__somos__interna{
	padding: 60px 0px;

	.cetis__item{
		max-width: 100%;

		.cetis__item__desc{
			text-align: justify;
		}
	}
}
.missao__interna{
	padding-top: 30px;
}
