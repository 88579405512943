.banner__carousel{
	overflow: hidden;
	position: relative;
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
}
.banner__inner{
	max-width: 1920px;
	width: 160%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.banner__indicators{
	margin-bottom: 40px;

	li{
		border: none;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		opacity: 1;
		position: relative;
		margin: 0px 8px;

		&:after{
			content: '';
			position: absolute;
			top: -4px;
			left: -4px;
			border-radius: 50%;
			border: 1px solid #fff;
			width: 22px;
			height: 22px;
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
	li.active{
		&:after{
			opacity: 1;
		}
	}
}
.cetis{
	background-color: #e7e7e7;
	padding: 40px 0px 20px 0px;
}
.cetis__item{
	margin-bottom: 20px;
}
.titulo__secao{
	font-family: 'montserratblack';
	font-size: 50px;
	line-height: 54px;
	color: #139549;
	position: relative;
	padding-bottom: 20px;
	text-align: center;
	margin-bottom: 40px;
	z-index: 5;
	display: inline-flex;

	&:after{
		bottom: 0px;
		width: 220px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.3s linear;
		content: '';
		height: 1px;
		background-color: #139549;
	}

	&:before{
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: #139549;
		content: '';
		position: absolute;
		bottom: -7px;
		left: 50%;
		transform: translateX(-50%);
	}

	.span__relativo{
		&:after{
			content: '';
			position: absolute;
			width: 11px;
			height: 11px;
			left: calc(50% + 20px);
			transform: translateX(-50%);
			bottom: -5px;
			border-radius: 50%;
			background-color: #139549;
		}
		&:before{
			content: '';
			position: absolute;
			width: 11px;
			height: 11px;
			left: calc(50% - 20px);
			transform: translateX(-50%);
			bottom: -5px;
			border-radius: 50%;
			background-color: #139549;
		}
	}
	.texto__fantasma{
		color: rgba(#579662, 0.5);
		position: absolute;
		z-index: -1;
		top: -30px;
		left: -45px;
	}
}
.cetis__desc{
	max-width: 550px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	color: #139549;
	text-align: center;
	margin-bottom: 45px;
}
.cetis__row{
	max-width: 930px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.cetis__item{
	max-width: 260px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.cetis__titulo{
	font-family: 'montserratextrabold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 15px;
	color: #139549;
	text-align: center;
}
.cetis__item__desc{
	text-align: center;
}
.cetis__header,
.servicos__header,
.produtos__header{
	text-align: center;
}
.servicos{
	padding: 60px 0px 50px 0px;
}
.servicos__card{
	max-width: 290px;
	position: relative;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	display: block;
	margin-bottom: 30px;
	transition: all 0.3s linear;
	padding: 30px 0px 0px 30px;
	transition: all 0.3s linear;
	height: 256px;
	overflow: hidden;

	&:hover{
		padding: 6px 0px 0px 6px;
		box-shadow: 10px 10px 10px rgba(#000, 0.4);

		.servicos__img{
			&:after{
				opacity: 1;
			}
		}
		.servicos__titulo{
			opacity: 1;
			left: 6px;
		}
	}

	&:before{
		content: '';
		position: absolute;
		z-index: -1;
		background-color: #139549;
		width: 128px;
		height: 128px;
		top: 0;
		left: 0;
	}
}
.servicos__img{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.7);
		z-index: 1;
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.servicos__titulo{
	width: calc(100% - 6px);
	height: 75px;
	bottom: 20px;
	left: 300px;
	position: absolute;
	z-index: 2;
	display: flex;
	align-items: center;
	font-family: 'montserratblack';
	font-size: 14px;
	line-height: 1.3em;
	color: #fff;
	background-color: #139549;
	padding: 10px;
	opacity: 0;
	transition: all 0.5s linear;

	span{
		--linhas: 3;
	}
}
.btn__conteudo{
	font-family: 'montserratextrabold';
	font-size: 14px;
	line-height: 14px;
	color: #fff;
	display: inline-block;
	background-color: #579662;
	padding: 8px 40px 6px 40px;
	border-radius: 40px;
	border: none;

	&:focus{
		outline: none;
	}

	&:hover{
		text-decoration: none;
		color: #fff;
	}
}
.servicos__btn{
	text-align: center;
	padding-top: 20px;
}
.newsletter{
	background-color: #579662;
	color: #fff;
	padding: 20px 0px;
}
.titulo__news{
	font-family: 'montserratblackitalic';
	font-size: 20px;
	margin-bottom: 5px;
}
.sub__news{
	font-family: 'montserratblack';
	font-size: 18px;
}
.news__row{
	align-items: center;
}
.newsletter__content{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.newsletter__icon{
	margin-right: 18px;
}
.segura__form{
	background-color: #fff;
	display: flex;
	justify-content: flex-end;
	padding: 7px 15px;
	border-radius: 25px;
}
.newsletter__input{
	font-family: 'montserratitalic';
	font-size: 18px;
	height: auto;
	line-height: 22px;
	padding: 0px;
	display: block;
	width: 100%;
	border: none;

	&:focus{
		box-shadow: none;
		border: none;
	}

	&::placeholder{
		color: #cccccc;
	}
}
.newsletter__form{
	width: 100%;
}
.produtos{
	padding: 80px 0px 30px 0px;
}
.produtos__card{
	max-width: 256px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	position: relative;
	display: block;

	&:hover{
		text-decoration: none;

		.produtos__txt{
			opacity: 1;

			.produtos__link{
				text-decoration: underline;
			}
		}
	}
}
.produtos__img{
	position: relative;
}
.produtos__txt{
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: rgba(#000, 0.7);
	width: 100%;
	height: 100%;
	color: #fff;
	opacity: 0;
	transition: all 0.3s linear;
}
.produtos__link{
	position: absolute;
	bottom: 8px;
	left: 50%;
	transform: translateX(-50%);
	font-family: 'montserratextrabold';
	font-size: 14px;
	line-height: 18px;
}
.produtos__titulo{
	font-size: 16px;
	line-height: 20px;
	height: 60px;
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 80px;
	display: flex;
	align-items: center;
	padding: 0px 10px;
	font-family: 'montserratextrabold';

	span{
		--linhas: 3;
	}
}
.owl-carousel.produtos__owl{
	.owl-dots{
		display: flex;
		justify-content: center;
		padding-top: 30px;

		.owl-dot{
			border: none;
			border-radius: 50%;
			width: 14px;
			height: 14px;
			opacity: 1;
			position: relative;
			margin: 0px 5px;
			background-color: #1d9950;

			&:focus{
				outline: none;
			}

			&:after{
				content: '';
				position: absolute;
				top: -4px;
				left: -4px;
				border-radius: 50%;
				border: 1px solid #1d9950;
				width: 22px;
				height: 22px;
				opacity: 0;
				transition: all 0.3s linear;
			}

			&.active{
				&:after{
					opacity: 1;
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.servicos__card{
		padding: 6px 0px 0px 6px;

		.servicos__titulo{
			left: 6px;
			opacity: 1;
		}

		.servicos__img{
			&:after{
				display: none;
			}
		}
	}
	.newsletter__titulos{
		margin-bottom: 20px;
		text-align: center;
	}
	.newsletter__content{
		flex-direction: column;
	}
	.newsletter__icon{
		margin-bottom: 10px;
	}
	.produtos__txt{
		opacity: 0.9;
		background-color: rgba(#000, 0.5);
	}
}
@include media-breakpoint-down (xs){
	.titulo__secao{
		font-size: 36px;

		.texto__fantasma{
			top: -25px;
			left: -30px;
		}
	}
}
