.rodape__conteudo{
	padding: 50px 0px 20px 0px;
	background-color: #139549;
	color: #fff;
	line-height: 1.4em;
}
.rodape__titulo{
	font-family: 'montserratextrabold';
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
}
.rodape__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.rodape__item{
	margin-bottom: 10px;
}
.rodape__endereco,
.rodape__telefone,
.rodape__email{
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
}
.imagem__rodape{
	width: 35px;
	display: flex;
	justify-content: center;
	font-size: 28px;
	line-height: 28px;
	margin-right: 10px;
}
.endereco__conteudo,
.telefone__conteudo,
.email__conteudo{
	padding-top: 7px;
}
.horario__atendimento{
	padding-top: 50px;
	margin-bottom: 35px;
	font-size: 16px;
	line-height: 20px;
}
.rodape__conteudo{
	.col-lg-4{
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.rodape__titulo{
			width: 100%;
		}
	}
}
.conteudo__mapa{
	max-width: 290px;
	margin-right: 0px;
	margin-left: auto;
}
.sociais__link{
	width: 30px;
	height: 30px;
	margin: 0px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #139549;
	background-color: #fff;
	border-radius: 50%;
	font-size: 18px;
	line-height: 18px;
	transition: all 0.3s linear;

	&:hover{
		background-color: darken(#fff, 10%);
		text-decoration: none;
		color: #139549;
	}
}
.titulo__sociais{
	margin-bottom: 8px;
	font-family: 'montserratextrabold';
	font-size: 16px;
	line-height: 20px;
}
.conteudo__sociais{
	display: flex;
	justify-content: center;
}
.rodape__creditos{
	background-color: #139549;
	border-top: 1px solid #fff;
	color: #fff;
}
.segura__creditos{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 18px 0px;
	font-size: 9px;
	text-align: center;
}
.gv8__box{
	display: flex;
	align-items: center;
}
.gv8__logo{
	margin-left: 10px;
}
.conteudo__mapa{
	.lazy-embed{
		--width: 223;
		--height: 177;
	}
}
@include media-breakpoint-down (md){
	.rodape__conteudo{
		text-align: center;
	}
	.rodape__menu{
		margin-bottom: 20px;
	}
	.rodape__endereco,
	.rodape__telefone,
	.rodape__email{
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.imagem__rodape{
		margin-right: 0px;
		margin-bottom: 5px;
	}
	.horario__atendimento{
		padding-top: 0px;
	}
	.rodape__sociais{
		margin-bottom: 20px;
	}
	.conteudo__mapa{
		max-width: 100%;

		.lazy-embed{
			--width: 1100;
			--height: 600;
		}
	}
}
