.produtos__interna{
	padding: 100px 0px 60px 0px;

	.produtos__card{
		margin-bottom: 70px;
	}
}
.paginacao{
	display: flex;
	justify-content: center;
	font-family: 'montserratblack';
	font-size: 14px;
	line-height: 14px;
}
.paginacao__item{
	color: #000;
	transition: all 0.3s linear;
	margin: 0px 8px;
	position: relative;
	display: inline-block;

	&:last-child{
		&:after{
			display: none;
		}
	}

	&.paginacao__item--active{
		color: #139549;

		&:after{
			color: #000;
		}
	}
	&:after{
		content: '|';
		position: absolute;
		top: 0;
		right: -9px;
		font-size: 12px;
		line-height: 12px;
		font-family: 'montserratblack';
	}

	&:hover{
		text-decoration: none;
		color: #139549;
	}
}
.produtos__interna__header{
	text-align: center;

	.span__relativo{
		font-size: 20px;
		line-height: 24px;
	}
}
.produtos__interna{
	.servicos__card{
		margin-bottom: 50px;
	}
}
.produto__interna__img{
	margin-bottom: 40px;
}
@include media-breakpoint-down(md){
	.produtos__interna{
		.row{
			.col-lg-6{
				&:first-child{
					order: 2;
				}
				&:last-child{
					order: 1;
				}
			}
		}
	}
}
