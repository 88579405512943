.contatos__interna{
	padding: 70px 0px 80px 0px;
}
.contatos__header{
	text-align: center;
}
.contatos__int__desc{
	max-width: 920px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 40px;
}
.contato__input{
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid #000000;
	font-size: 16px;
	line-height: 20px;
	height: auto;
	padding: 5px 34px;
	resize: none;

	&::placeholder{
		color: rgba(#000, 0.4);
	}
	&:focus{
		box-shadow: none;
		border-color: #000000;
	}
}
.contatos__form{
	.form-group{
		margin-bottom: 30px;
	}
}
.btn__contato{
	text-align: right;
}
