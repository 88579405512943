.topo__faixa{
	background-color: #139549;
	color: #fff;
	font-family: 'montserratlight';
	font-size: 12px;
	line-height: 16px;

	.fas{
		font-size: 18px;
		margin-right: 10px;
	}
}
.segura__faixa{
	display: flex;
	align-items: center;
	padding: 5px 0px;
}
.faixa__bem__vindo{
	--linhas: 1;
}
.bg__menu{
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: none;
}
.faixa__contato{
	display: flex;
	align-items: center;
}
.faixa__mail{
	margin-right: 30px;
	display: flex;
	align-items: center;
}
.faixa__tel{
	display: flex;
	align-items: center;
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.menu__link{
	font-family: 'montserratextrabold';
	color: #139549;
	position: relative;
}
.dropmenu__ctrls{
	display: flex;
	align-items: center;
	position: relative;
}
.dropmenu{
	background-color: #e7e7e7;
	list-style: none;
	margin-bottom: 0px;
	padding-left: 0px;
}
.dropmenu__link{
	display: block;
	padding: 15px;
	font-family: 'montserratmedium';
	color: #000;
	border-bottom: 2px solid #b2d2bf;
	transition: all 0.3s linear;

	&:hover{
		font-family: 'montserratextrabold';
		color: #139549;
		text-decoration: none;
	}
}
.dropmenu__item{
	&:last-child{
		.dropmenu__link{
			border-bottom: none;
		}
	}
}
.menu__item--active{
	.menu__link,
	.dropmenu__ctrls{
		&:after{
			opacity: 1;
		}
	}
}
@include media-breakpoint-up (lg){
	.segura__faixa{
		justify-content: space-between;
	}
	.mbl__contato,
	.topo__mbl,
	.dropmenu__btn{
		display: none;
	}
	.topo__main{
		background-color: #fff;
	}
	.segura__main{
		display: flex;
		justify-content: space-between;
	}
	.main__menu{
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
	}
	.main__logo{
		display: block;
		margin-right: 230px;
	}
	.menu__link{
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover{
			color: #139549;
			text-decoration: none;

			&:after{
				opacity: 1;
			}
		}

		&:after{
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			position: absolute;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: #139549;
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
	.dropmenu__ctrls{
		position: relative;
		padding-right: 36px;
		cursor: pointer;

		&:before{
			content: '';
			position: absolute;
			top: 49%;
			right: 0px;
			transform: translateY(-50%);
			border-top: 10px solid #139549;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}

		.menu__link{
			&:after{
				display: none;
			}
		}
		&:after{
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			position: absolute;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: #139549;
			opacity: 0;
			transition: all 0.3s linear;
		}

		&:hover{
			&:after{
				opacity: 1;
			}
		}
	}
	.menu__item{
		display: flex;
		position: relative;

		&:hover{
			.dropmenu{
				display: block;
			}
		}
	}
	.topo{
		z-index: 50;
		position: sticky;
		top: -27px;
		box-shadow: 0px 0px 20px rgba(#000, 0.7);
	}
	.dropmenu{
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 315px;
		max-width: 315px;
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		display: block;
		margin-right: 120px;
	}
}
@include media-breakpoint-down (md){
	.topo{
		z-index: 50;
		box-shadow: 0px 0px 20px rgba(#000, 0.7);
		position: sticky;
		top: -26;
	}
	.topo__mbl{
		background-color: #fff;
	}
	.segura__faixa{
		justify-content: center;
	}
	.faixa__contato{
		display: none;
	}
	.topo__main{
		position: fixed;
		width: 250px;
		left: -250px;
		top: 0px;
		transition: all 0.3s linear;
		height: 100%;
		overflow-y: auto;
		background-color: #fff;
		z-index: 1000;
	}
	.topo__main--shown{
		left: 0px;
	}
	.segura__main{
		padding: 0px;
	}
	.main__logo{
		display: block;
		border-bottom: 2px solid #b2d2bf;
		padding: 10px;
	}
	.menu__item{
		border-bottom: 2px solid #b2d2bf;
	}
	.menu__item--active{
	.menu__link,
	.dropmenu__ctrls{
		color: #fff;
		background-color: #139549;

			.dropmenu__btn{
				&:after{
					border-top: 10px solid #fff;
				}
			}
		}
	}
	.menu__link{
		display: block;
		padding: 10px;
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			background-color: #139549;
			color: #fff;
		}
	}
	.dropmenu__ctrls{
		justify-content: space-between;
		padding-right: 10px;
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			background-color: #139549;
			color: #fff;

			.menu__link{
				color: #fff;
			}
		}
	}
	.main__menu{
		margin-bottom: 30px;
	}
	.mbl__mail,
	.mbl__tel{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;
		font-size: 15px;

		.fas{
			margin-bottom: 5px;
		}
	}
	.dropmenu__btn{
		background-color: transparent;
		border: none;
		position: relative;
		align-items: center;
		padding: 4px;

		&:after{
			content: '';
			position: relative;
			display: inline-block;
			border-top: 10px solid #139549;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			transition: all 0.3s linear;
		}

		&[aria-expanded="true"]{
			&:after{
				transform: rotate(90deg);
			}
		}

		&:focus{
			outline: none;
		}
	}
	.dropmenu__ctrls{

		&:hover{
			.dropmenu__btn{
				&:after{
					border-top: 10px solid #fff;
				}
			}
		}
	}
	.segura__mbl{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mbl__botao{
		background-color: transparent;
		border: none;
		font-size: 26px;
		color: #000;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-down (sm){
	.mbl__logo{
		max-width: 200px;
		display: block;
	}
}
@include media-breakpoint-down (xs){
	.mbl__logo{
		max-width: 180px;
	}
}
